import * as JSPM from "jsprintmanager";
import { default as EscPosEncoder } from "@manhnd/esc-pos-encoder";
//const base_url = process.env.VUE_APP_API_BASE_URL;

function print(order) {
  let cpj = new JSPM.ClientPrintJob();
  cpj.clientPrinter = new JSPM.DefaultPrinter();
  let img = new Image();
  img.src = "/logo-bw.png";
  let encoder = new EscPosEncoder();
  img.onload = () => {
    let resultImage = encoder
      .initialize()
      .align("center")
      .image(img, 256, 88, "atkinson")
      .newline()
      .align("center")
      .line("YASSIR Express")
      .align("center")
      .line("Commande : " + order.tracking_number_ext)
      .align("center")
      .line(order.created_at)
      .bold(true)
      .size("normal")
      .line("______________________________________________")
      .bold(false)
      .line("Designation                   Prix   Qte Totale")
      .size("small");
    for (let index = 0; index < order.products.length; index++) {
      //Initial spaces , to be uniforme
      let des = "                             ";
      let prix = "        ";
      let qte = "  ";
      let p = order.products[index];
      let name = p.name.length > 20 ? p.name.substring(0, 25) + "..." : p.name;
      name = name + des.substring(name.length);
      console.log("p.price.length", p.price, p.price.toString().length);
      prix = p.price + prix.substring(p.price.toString().length);
      qte = p.qte_in_order + qte.substring(p.qte_in_order.toString().length);
      let prod = name + prix + qte + " " + p.total_item.toFixed(2);
      resultImage = resultImage.line();
      resultImage = resultImage.text(prod);
    }
    resultImage = resultImage
      .newline()
      .line("______________________________________________");
    let ttc = "                                            ";
    resultImage = resultImage
      .line(
        "TTC :" +
          ttc.substring(order.ttc.toFixed(2).toString().length + 5) +
          order.ttc.toFixed(2) +
          " DA"
      )
      .line(
        "Reduction :" +
          ttc.substring(order.reduction.toFixed(2).toString().length + 11) +
          order.reduction.toFixed(2) +
          " DA"
      )
      .line(
        "Timbre    :" +
          ttc.substring(order.stamp_amount.toFixed(2).toString().length + 11) +
          order.stamp_amount.toFixed(2) +
          " DA"
      )

      .bold(true)
      .line(
        "TOTAL     :" +
          ttc.substring(order.total.toFixed(2).toString().length + 11) +
          order.total.toFixed(2) +
          " DA"
      )
      .bold(false)
      .bold(true)
      .line("______________________________________________")
      .bold(false)
      .line(
        "Le retour des produits sera accepte sous 48H sur presentation du ticket, il fera l'objet d'un avoir et nom d'un remboursement"
      )
      .bold(true)
      .line("______________________________________________")
      .bold(false)
      .align("center")
      .cut("full")
      .encode();
    cpj.binaryPrinterCommands = resultImage;
    cpj.sendToClient();
  };
}

function init() {
  JSPM.JSPrintManager.auto_reconnect = true;
  JSPM.JSPrintManager.start();
}

export default {
  init,
  print,
};
