<template>
  <v-container
    v-if="!is_loading_dn"
    fluid
    fill-height
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        md4
      >
        <v-skeleton-loader
          class="mx-auto border"
          type="table-tbody"
        ></v-skeleton-loader>
      </v-flex>

      <v-flex
        md1
        d-flex
        align-center
        justify-center
        class="mb-8"
      >
        <v-divider vertical></v-divider>
      </v-flex>

      <v-flex
        xs12
        md7
      >
        <v-flex style="height: 70vh; overflow-y: scroll; overflow-x: hidden">
          <v-row dense>
            <v-col
              cols="12"
              sm="3"
              v-for="n in 9"
              :key="n"
            >
              <v-skeleton-loader
                class="mx-auto border"
                max-width="200"
                type="image, article"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>

  <v-container
    v-else
    fluid
    fill-height
    class="container"
  >
    <v-layout
      row
      wrap
    >
      <!-- first column -->
      <v-flex
        xs12
        md4
        class="align-content-space-between flex-container"
        style="display: flex; flex-direction: column; height: 100%"
      >
        <!-- sales details -->
        <div
          style="
            height: 70vh;
            overflow-y: scroll;
            overflow-x: hidden;
            min-height: 80px;
          "
          class="scroll pl-4"
        >
          <h3 class="text-h4">{{ $t("pos_n.sale_details") }}</h3>
          <!-- empty cart -->
          <div
            v-if="listProductsSelected.length == 0"
            class="d-flex flex-column justify-center align-center mt-12"
          >
            <img
              src="@/assets/empty-cart.png"
              alt="empty-cart"
              style="width: 8rem; height: 8rem"
            />
            <div class="d-flex flex-column justify-center align-center mt-8">
              <div
                style="
                  font-family: Roboto;
                  font-size: 24px;
                  font-weight: 700;
                  line-height: 32px;
                  letter-spacing: 0px;
                  text-align: center;
                "
              >
                {{ $t("pos_n.empty_cart") }}
              </div>
              <div
                style="
                  font-family: Roboto;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                  text-align: center;
                  color: #524977;
                "
              >
                {{ $t("pos_n.add_some_items") }}
              </div>
            </div>
          </div>
          <!-- end empty cart -->
          <!-- items loop -->
          <div
            @click="select(item)"
            v-for="item in listProductsSelected"
            :key="item.id"
          >
            <v-row>
              <v-col
                cols="1"
                class="px-0 d-flex justify-end"
              >
                <v-icon @click="remove(item)">mdi-close</v-icon>
                <v-icon
                  :color="
                    Array.isArray(item?.promotions) &&
                    item.promotions.length > 0
                      ? 'primary'
                      : 'grey lighten-1'
                  "
                  @click.stop="
                    Array.isArray(item?.promotions) &&
                      item.promotions.length > 0 &&
                      openPromotionModal(item)
                  "
                >
                  {{ item?.reduction ? "mdi-sale" : "mdi-sale-outline" }}
                </v-icon>
              </v-col>
              <v-col
                cols="2"
                class="px-0 d-flex justify-start"
              >
                <v-img
                  v-if="item.image"
                  :src="item.image.url"
                  aspect-ratio="1"
                  height="70"
                  width="70"
                  contain
                />
              </v-col>

              <v-col
                cols="6"
                class="d-flex flex-column"
                style="
                  justify-content: center;
                  align-items: flex-start;
                  cursor: pointer;
                "
              >
                <div
                  class="font-weight-regular"
                  style="color: black"
                >
                  {{ getDisplayName(item) }}
                </div>

                <div>
                  <label>
                    <span class="font-weight-bold">{{
                      item.qte_in_order
                    }}</span>
                    <span class="text--disabled">
                      Unites a {{ item.price_ht }} DA/Unites</span
                    >
                    <span
                      class="text--disabled"
                      v-if="item?.promotion"
                    >
                      ({{ item.promotion.discount }}%)</span
                    >
                  </label>
                </div>
                <template v-if="item?.qte_in_order > item?.qte_in_promotion">
                  <label>
                    <span class="font-weight-bold">{{
                      $t("pos_n.qte_with_promotion", {
                        qte: item?.qte_in_promotion,
                      })
                    }}</span>
                    <br />
                    <span class="font-weight-bold">{{
                      $t("pos_n.qte_without_promotion", {
                        qte: item?.qte_in_order - item?.qte_in_promotion,
                      })
                    }}</span>
                  </label>
                </template>
              </v-col>

              <v-col
                cols="3"
                class="d-flex flex-column justify-center align-center"
              >
                <p class="font-weight-medium align-self-end mb-0">
                  {{ formatPrice(item.total_item) }}
                </p>
                <p
                  v-if="item?.reduction"
                  class="font-weight-light text-decoration-line-through align-self-end mb-0"
                >
                  {{ formatPrice(item.price * item.qte_in_order) }}
                </p>
              </v-col>
            </v-row>

            <v-divider></v-divider>
          </div>
          <!-- end items loop -->
        </div>
        <!-- end sales details -->
        <!-- calculator -->
        <div
          class="ml-8 mt-0 bottom"
          style="margin-bottom: 20px"
        >
          <v-divider class="ms-4"></v-divider>

          <!-- total section -->
          <v-row class="mr-2">
            <v-spacer></v-spacer>
            <v-col md6>
              <div
                class="text-h5 font-weight-medium d-flex justify-space-between py-2"
                style="min-width: 200px"
              >
                <span>Total </span>
                <span>{{ total_ttc().toFixed(2) }}</span>
              </div>

              <div
                class="d-flex justify-space-between"
                style="min-width: 200px"
              >
                <p>Taxes</p>
                <p>{{ totalTva().toFixed(2) }}</p>
              </div>
              <v-divider class="ms-1"></v-divider>
            </v-col>
          </v-row>
          <!-- end total section -->
          <v-row justify="space-between">
            <v-col cols="3">
              <v-btn
                :disabled="listProductsSelected.length == 0"
                color="primary"
                small
                style="
                border-radius: 4px;
                box-shadow: listProductsSelected.length == 0 ? 'none' : '0px 1px 5px 0px #0000001F, 0px 2px 2px 0px #00000024, 0px 1px 1px 0px #00000033';
              "
                @click="openPayementModal()"
              >
                {{ $t("pos_n.payment") }}
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn
                text
                small
                color="primary"
                :disabled="listProductsSelected.length == 0"
                @click="clearCart()"
                style="
    color: listProductsSelected.length > 0 ? '#6200EA' : '#AFAAC2';
  "
              >
                <span style="text-decoration: underline">{{
                  $t("pos_n.clear_cart")
                }}</span>
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn
                text
                small
                color="primary"
                @click="
                  $admin.can('order-refund')
                    ? openRefundModal()
                    : openPermissionErrorModal()
                "
              >
                <v-icon>mdi-reload</v-icon>
                <span>{{ $t("pos_n.refund") }}</span>
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn
                text
                small
                color="primary"
                :disabled="!selected_cart_item"
                @click="openmodal()"
              >
                <v-icon>mdi-information-outline</v-icon>
                <span>infos</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- end calculator -->
      </v-flex>
      <!-- end first column -->
      <!-- divider -->

      <v-flex shrink>
        <v-divider vertical></v-divider>
      </v-flex>

      <!-- end divider -->
      <!-- second column -->
      <v-flex
        xs12
        md8
        class="pl-4"
      >
        <v-data-table
          :items="displayedItems"
          :loading="is_loading_product"
          :server-items-length="totalItems"
          :options.sync="options"
          :items-per-page="18"
          fill-height
          hide-default-footer
        >
          <template v-slot:top>
            <v-row justify="end">
              <v-btn
                color="primary"
                outlined
                @click="getAllProducts()"
                :loading="isLoadingProductsToLDB"
                :disabled="isLoadingProductsToLDB"
                class="mr-4"
              >
                {{ $t("fetch_new_products") }}
              </v-btn>
              <v-dialog
                v-model="isVisibleCheckoutDialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t("generate_pos_checkout") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">{{
                    $t("generate_pos_checkout_confirmation_message")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      text
                      @click="closeForm"
                      >{{ $t("cancel") }}</v-btn
                    >
                    <v-btn
                      color="primary"
                      text
                      @click="printPOSCheckout()"
                      >{{ $t("yes") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
            <div style="margin-top: -45px">
              <h3 class="text-h4">{{ $t("pos_n.select") }}</h3>
              <div class="my-4">
                <v-row>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <v-text-field
                      ref="searchField"
                      autofocus
                      outlined
                      hide-details=""
                      dense
                      :label="$t('search')"
                      v-model="options.search"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </div>
          </template>
          <template v-slot:body="{ items }">
            <v-flex
              style="height: 70vh; overflow-y: scroll; overflow-x: hidden"
            >
              <v-row
                dense
                v-if="items.length > 0"
              >
                <v-col
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <v-card
                    width="230"
                    height="300"
                    @click="addToCart(item)"
                  >
                    <v-card-text>
                      <div class="icon-container">
                        <v-icon
                          color="primary"
                          v-if="isProductSelected(item)"
                          >mdi-check</v-icon
                        >
                      </div>
                      <div class="promo-container">
                        <v-img
                          v-if="item?.promotion"
                          src="@/assets/promo.png"
                          max-width="50"
                        ></v-img>
                      </div>

                      <template>
                        <div
                          class="image-container"
                          v-if="item.image"
                        >
                          <v-img
                            :src="item.image.url"
                            height="130"
                          />
                        </div>
                        <v-img
                          class="ms-9"
                          v-else
                          v-bind:style="{
                            'min-height': '130px',
                            'min-width': '130px',
                            'object-fit': 'cover',
                            'background-image': 'url(\'@/assets/product.png\')',
                            'background-size': 'cover',
                          }"
                        />
                      </template>

                      <p class="mt-5 mb-3 text-h4 text--primary">
                        {{ item.name.substring(0, 18) + ".." }}
                      </p>
                      <p class="text--disabled mt-n3 mb-0 body-1">
                        {{ item.barcode }}
                      </p>
                      <p class="font-weight-medium">
                        {{ item.price }} {{ user.currency }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-alert
                v-else-if="!is_loading_product"
                :value="true"
                icon="mdi-alert-circle-outline"
                >{{ $t("no_data_available") }}</v-alert
              >
            </v-flex>
          </template>
          <template v-slot:foot="{ pagination, options, updateOptions }">
            <div class="footer-container">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                :items-per-page-options="[9, 18, 27]"
                :items-per-page-text="$t('pos_n.per_page')"
              >
                <template
                  v-slot:page-text="{ pageStart, pageStop, itemsLength }"
                >
                  {{
                    $t("pos_n.displaying", {
                      from: pageStart,
                      to: pageStop,
                      total: itemsLength,
                    })
                  }}
                </template>
              </v-data-footer>
            </div>
          </template>
        </v-data-table>
        <product-info-modal
          v-if="isProductInfoModalOpen"
          :isProductInfoModalOpen="isProductInfoModalOpen"
          :toggleProductInfoModal="() => toggleProductInfoModal()"
          :productItem="currentItem"
        ></product-info-modal>
        <payement-modal
          v-if="isPayementModalOpen"
          :isPayementModalOpen="isPayementModalOpen"
          :togglePayementModal="togglePayementModal"
          :total="total_ttc()"
          :order="listProductsSelected"
        >
        </payement-modal>
        <refund-modal
          v-if="isRefundModalOpen"
          :isRefundModalOpen="isRefundModalOpen"
          :toggleRefundModal="() => toggleRefundModal()"
          :user="user"
        >
        </refund-modal>
        <add-quantity-modal
          v-if="isAddQuantityModalOpen"
          :isAddQuantityModalOpen="isAddQuantityModalOpen"
          :toggleAddQuantityModal="() => toggleAddQuantityModal()"
          :productItem="currentItemInCart"
        >
        </add-quantity-modal>
        <promotion-modal
          v-if="isPromotionModalOpen"
          :isPromotionModalOpen="isPromotionModalOpen"
          :product="currentItemPromotion"
          @reCalculate="total_ttc_item"
          :togglePromotionModal="() => togglePromotionModal()"
        ></promotion-modal>
      </v-flex>
      <!-- end second column -->
    </v-layout>
  </v-container>
</template>
<style scoped>
.flex-container {
  justify-content: space-between;
}

.bottom {
  margin-top: auto;
}
</style>


<script>
import Localbase from "localbase";
import { validationRules } from "@/mixins/validationRules";
import { network } from "@/mixins/network";
import _ from "lodash";
import debounce from "lodash/debounce";
import { mapGetters } from "vuex";
import Vue from "vue";
import PayementModal from "./modals/payementModal.vue";
import ProductInfoModal from "./modals/productInfoModal.vue";
import RefundModal from "./modals/refundModal.vue";
import PromotionModal from "./modals/promotionModal.vue";
import AddQuantityModal from "./modals/addQuantityModal.vue";
let db = new Localbase("db");
db.config.debug = false;
import productService from "@/store/services/product-service";
export default {
  components: {
    ProductInfoModal,
    PayementModal,
    RefundModal,
    PromotionModal,
    AddQuantityModal,
  },
  mixins: [validationRules, network],
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105)
      ) {
        this.append(e.key);
      }
    });
  },
  mounted() {
    document.addEventListener("keydown", this.handleBarcodeInput);
    db.collection("products")
      .get()
      .then(async (products) => {
        if (!_.isEmpty(products) && this.isSyncToday())
          this.listLocalProducts = products;
        else {
          this.getAllProducts();
        }
        this.is_loading_dn = true;
      });
    setInterval(() => {
      db.collection("products")
        .get()
        .then((doc) => {
          this.listLocalProducts = doc;
        });
    }, 100 * 1000);
    document.addEventListener("keypress", function (e) {
      if (e.code == "NumpadAdd" || e.code == "Equal") {
        document.getElementById("saveBtn").focus();
      }
      if (e.code == "NumpadSubtract") {
        document.getElementById("amount_received_input").focus();
      }
    });
    const editedUserJSON = localStorage.getItem("user");
    if (editedUserJSON) {
      const editedUser = JSON.parse(editedUserJSON);
      this.$store.commit("users/EDITED_USER", editedUser);
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleBarcodeInput);
  },
  computed: {
    ...mapGetters({
      isLoadingProducts: "products/isLoadingProducts",
      listProducts: "products/listProducts",
      productsMeta: "products/meta",
      lots: "lots/list",
      user: "users/editedUser",
    }),
    totalItems() {
      return this.productsMeta?.totalItems ?? this.itemsLength;
    },
  },
  watch: {
    options: {
      handler: debounce(async function (params) {
        if (this.scanning) return;
        this.is_loading_product = true;
        try {
          params.hub_id = this.user?.hub_id;
          await this.$store.dispatch("products/list", params);
          this.displayedItems = this.listProducts;
          if (params.page > this.productsMeta.lastPage) {
            params.page = 1;
          }
        } catch (error) {
          this.displayedItems?.forEach((value) => {
            Vue.set(value, "selected", false);
          });
          let filteredItems = this.listLocalProducts.filter((item) => {
            return (
              item.name
                .toLowerCase()
                .includes(this.options.search.toLowerCase()) ||
              item.barcode.includes(this.options.search)
            );
          });
          let startIndex = (params.page - 1) * params.itemsPerPage;
          let endIndex = startIndex + params.itemsPerPage;
          this.itemsLength = filteredItems.length;
          this.displayedItems = filteredItems.slice(startIndex, endIndex);
        }
        this.is_loading_product = false;
      }, 1000),
      deep: true,
    },
  },
  data() {
    return {
      itemsLength: 0,
      current: 0,
      paginatedItems: [],
      currentPage: 1,
      itemsPerPage: 16,
      page: 1,
      is_loading_product: false,
      isLoadingProductsToLDB: false,
      posOrder: {},
      listLocalProducts: [],
      networkProducts: [],
      displayedItems: [],
      isVisibleCheckoutDialog: false,
      is_loading_dn: false,
      is_saving: false,
      show_created_at_picker: null,
      search_product_input: "",
      is_valid: true,
      formErrors: [],
      options: { search: "" },
      orderProductToAdd: {
        qte_in_order: 1,
        discount: 0,
      },
      listProductsSelected: [],
      isProductInfoModalOpen: false,
      isPayementModalOpen: false,
      isRefundModalOpen: false,
      isAddQuantityModalOpen: false,
      currentItemInCart: null,
      selected_cart_item: false,
      selectedItem: null,
      activatedProducts: [],
      isPromotionModalOpen: false,
      scanning: false,
    };
  },
  methods: {
    formatPrice(price) {
      return `${price.toFixed(2)} ${this.user.currency}`;
    },
    getDisplayName(item) {
      if (item && item.name) {
        return item.name.length > 30
          ? item.name.substring(0, 27) + ".."
          : item.name;
      }
      return "";
    },
    addQty(item) {
      item.qte_in_order = parseInt(item.qte_in_order) + 1;
      item.total_item = this.total_ttc_item(item);
    },
    subQty(item) {
      if (item.qte_in_order != 1) {
        item.qte_in_order = parseInt(item.qte_in_order) - 1;
        item.total_item = this.total_ttc_item(item);
      }
    },
    inputQty(item) {
      item.total_item = this.total_ttc_item(item);
    },

    clearCart() {
      this.$swal({
        title: this.$t("clear_cart"),
        text: this.$t("clear_cart_confirmation"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("confirm"),
      }).then((result) => {
        if (result.value) {
          this.listProductsSelected = [];
          this.activatedProducts = [];
          this.$store.dispatch("alerts/success", this.$t("cart_cleared"));
        }
      });
    },
    openPermissionErrorModal() {
      this.$swal({
        title: this.$t("pos_n.permission_error"),
        text: this.$t("pos_n.permission_error_message"),
        icon: "error",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("confirm"),
      });
    },
    async handleBarcodeInput(event) {
      if (event.keyCode === 13) {
        this.scanning = true;
        const barcode = event.target.value;
        const item = this.listLocalProducts.find(
          (item) => item.barcode === barcode
        );
        if (!item) {
          this.$store.dispatch(
            "alerts/error",
            this.$t("invalid_barcode", { barcode: barcode })
          );
          this.scanning = false;
          return;
        }
        await this.addToCart(item);
        this.options.search = "";
        await new Promise((resolve) => setTimeout(resolve, 1000)); //  delay so watch wont fire search request, to find another solution
        this.scanning = false;
      }
    },
    itemLabel(item) {
      const label =
        item.qte_in_order + " Unites a " + item.price_ht + " DA/Unites";
      if (item?.promotion) {
        return label + ` (${item.promotion.discount}%)`;
      }
      return label;
    },
    isProductSelected(item) {
      return this.activatedProducts.indexOf(item.id) !== -1;
    },
    cancelSearch() {
      this.options.search = "";
    },

    async addToCart(item) {
      // Check if item already exists in listProductsSelected
      const selectedItem = this.listProductsSelected.find(
        (selectedItem) => selectedItem.id === item.id
      );
      if (selectedItem) {
        // Item already exists, increment qte_in_order property
        selectedItem.qte_in_order++;
        // calculate total price ttc => ht*qte + (ht*qte)*tva/100
        selectedItem.total_item = this.total_ttc_item(selectedItem);
      } else {
        this.activatedProducts.push(item.id);
        // Item doesn't exist, add new item with qte of 1
        const newItem = {
          ...item,
          qte_in_order: 1,
          selected_cart: false,
          selected: true,
        };
        if (newItem?.promotion) {
          newItem.qte_in_promotion = this.totalQteInPromotion(newItem);
        }
        newItem.total_item = this.total_ttc_item(newItem);
        try {
          const hub_id = this.user?.hub_id;
          const promotions = await productService.getDiscounts(item.id, hub_id);
          newItem.promotions = promotions;
        } catch (error) {
          newItem.promotions = newItem.promotions.filter(
            (promotion) => new Date(promotion.end_date) > new Date()
          );
          if (newItem.promotions.length > 0) {
            newItem.promotion = newItem.promotions[0];
          }
        }
        this.listProductsSelected.unshift(newItem);
      }
      this.$refs.searchField.focus();
    },
    remove(item) {
      const index = this.listProductsSelected.indexOf(item);
      if (index != -1) {
        if (item.selected_cart == true) this.selected_cart_item = false;
        this.listProductsSelected.splice(index, 1);
        item.selected = false;
        this.activatedProducts.splice(index, 1);
        if (this.currentItemInCart && item.id == this.currentItemInCart.id) {
          this.currentItemInCart = null;
        }
      }
    },
    clearAll() {
      this.current = 0;
    },
    append(number) {
      if (this.current == 0) this.current = `${number}`;
      else this.current = `${this.current}${number}`;
    },
    dot() {
      if (this.current.indexOf(".") == -1) this.append(".");
    },
    addQuantity() {
      this.total_ttc_item(this.currentItemInCart);
    },
    totalQteInPromotion(item) {
      const product = item.promotion.products.find(
        (product) => product.product_id === item.id
      );

      if (!product || !Array.isArray(product.lots)) {
        return 0;
      }
      const total = product.lots.reduce((sum, lot) => sum + lot.qte_in_lot, 0);

      return total;
    },
    calulateDiscountAmount(item) {
      if (!item?.promotion) {
        return 0;
      }
      const itemHt = this.ht_price(
        item.ht,
        item.price_ht,
        Math.min(item.qte_in_order, item.qte_in_promotion)
      );
      if (item?.qte_in_order > item?.qte_in_promotion) {
        item.qte_without_promo = item.qte_in_order - item.qte_in_promotion;
      }
      const discountAmount =
        Math.round(itemHt * parseFloat(item?.promotion?.discount ?? 0)) / 100;
      const maxDiscountAmount = item?.promotion?.max_discount_amount ?? 0;
      return (item.reduction =
        maxDiscountAmount > 0
          ? Math.min(discountAmount, maxDiscountAmount)
          : discountAmount);
    },
    total_ttc_item(item) {
      const result =
        this.item_ht(item) +
        this.item_tva(item) -
        this.calulateDiscountAmount(item);
      return (item.total_item = Math.round(result * 100) / 100);
    },
    item_tva(item) {
      const tva = parseFloat(item.tva);
      return (item.total_tva =
        ((this.item_ht(item) - this.calulateDiscountAmount(item)) * tva) / 100);
    },
    ht_price(ht, price_ht, qte_in_order) {
      return (price_ht = parseFloat(price_ht) * parseFloat(qte_in_order ?? 1));
    },
    item_ht(item) {
      return (item.ht =
        parseFloat(item.price_ht) * parseFloat(item?.qte_in_order ?? 1));
    },
    total_ttc() {
      var total = 0;
      this.listProductsSelected?.forEach((element) => {
        total += element.total_item;
      });
      return Number(total.toFixed(2));
    },
    totalTva() {
      var total = 0;
      this.listProductsSelected?.forEach((element) => {
        total += element.total_tva;
      });
      return Number(total.toFixed(2));
    },

    select(item) {
      this.currentItemInCart = item;
      this.openAddQuantityModal();
      if (this.selectedItem === item) {
        this.selectedItem = null;
        item.selected_cart = false;
        this.selected_cart_item = false;
      } else {
        if (this.selectedItem) {
          this.selectedItem.selected_cart = false;
          this.selected_cart_item = false;
        }
        item.selected_cart = true;
        this.selected_cart_item = true;
        this.selectedItem = item;
      }
    },
    async openmodal() {
      if (this.currentItemInCart) {
        await this.$store.dispatch("lots/list", {
          product_id: this.currentItemInCart.id,
        });
        this.isProductInfoModalOpen = true;
        this.currentItem = this.currentItemInCart;
      }
    },

    toggleProductInfoModal() {
      this.isProductInfoModalOpen = !this.isProductInfoModalOpen;
    },
    openPayementModal() {
      this.isPayementModalOpen = true;
    },
    openRefundModal() {
      this.isRefundModalOpen = true;
    },
    openAddQuantityModal() {
      this.isAddQuantityModalOpen = true;
    },
    togglePayementModal(clearCart = false) {
      if (clearCart) {
        this.listProductsSelected = [];
        this.activatedProducts = [];
      }
      this.isPayementModalOpen = !this.isPayementModalOpen;
      this.$refs.searchField.focus();
    },
    toggleRefundModal() {
      this.isRefundModalOpen = !this.isRefundModalOpen;
    },
    toggleAddQuantityModal() {
      this.isAddQuantityModalOpen = !this.isAddQuantityModalOpen;
      this.addQuantity();
    },
    togglePromotionModal() {
      this.isPromotionModalOpen = !this.togglePromotionModal;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        // charCode > 31 &&
        charCode < 48 ||
        charCode > 57
        // charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    openPromotionModal(item) {
      this.isPromotionModalOpen = true;
      this.currentItemPromotion = item;
    },
    timeout(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    isSyncToday() {
      const day = localStorage.getItem("day");
      const dateNow = new Date();
      if (dateNow.getDate() == day) {
        return true;
      }
      localStorage.setItem("day", dateNow.getDate());
      return false;
    },
    async getAllProducts() {
      this.isLoadingProductsToLDB = true;
      try {
        await db.collection("products").delete();
        const { products } = await this.$store.dispatch(
          "products/productsQuery",
          {
            hub_id: this.user?.hub_id,
            all: "true",
          }
        );
        const mappedData = products.map((item) => ({
          id: item.id,
          barcode: item.barcode,
          type: item.type,
          name: item.name,
          price: item.price,
          price_ht: item.price_ht,
          purchase_price_ht: item.purchase_price_ht,
          tva: item.tva,
          selected: false,
          qte_in_hub: item.qte_in_hub,
          bundle_items: item.bundle_items,
          promotion: item?.promotion,
          promotions: item?.promotions,
          image: item?.image?.url && { url: item.image.url },
          _key: item.barcode,
        }));
        await db.collection("products").set(mappedData, { keys: true });
        this.listLocalProducts = mappedData;
        this.isLoadingProductsToLDB = false;
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.statusText);
        this.isLoadingProductsToLDB = false;
      }
    },
    async printPOSCheckout() {
      try {
        this.is_loading_dn = false;
        await this.$store.dispatch("sync/syncOrders");
        const data = await this.$store.dispatch("orders/getPosCheckoutA7");
        const blob = new Blob([data], { type: "application/pdf" });
        const blobURL = URL.createObjectURL(blob);
        const iframe = document.createElement("iframe");
        document.body.appendChild(iframe);
        iframe.style.display = "none";
        iframe.id = "invoice_iframe";
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 100);
        };
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.statusText);
      } finally {
        this.is_loading_dn = true;
      }
      this.closeForm();
    },
    closeForm() {
      this.isVisibleCheckoutDialog = false;
    },
  },
};
</script>
<style >
.calculator {
  position: relative;
  top: 20px;
  height: 200px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  text-align: center;
  font-size: 30px !important;
  left: 30px;
}
.twoGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 4;
  border: 1px solid rgb(227, 224, 224);
}
.quantity {
  grid-column-start: 4;
  grid-row-start: 3;
  grid-row-end: 5;
  display: flex;
  margin: 5px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  border: 1px solid rgb(227, 224, 224);
  background-color: #6200ea !important;
  font-size: 20px;
  font-weight: 700;
}
.btn {
  border: 1px solid rgb(227, 224, 224);
  padding: 15px;
  height: 60px;
  width: 100px;
}

.esc {
  border: 1px solid #b10000;
  color: #b10000;
  font-size: 20px;
}

.scroll {
  overflow-y: scroll;
}
.title-line-clamp {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.icon-container {
  width: 100%;
  height: 8px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
}
.promo-container {
  width: 100%;
  height: 8px;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8px;
}
.footer-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.v-data-footer {
  font-size: 15px;
}

.bg-highlight {
  background-color: rgb(234, 229, 229);
}
</style>  
